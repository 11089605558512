@menu-bg-color: #f3f4f7;
@header-top-height: 90px;
@menu-item-color: #0d0d0d;

.banner-top {
  display: none;
  padding: 10px 0 1px 0;
  background-color: #f3f4f7;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.025em;
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    max-width: 1700px;
    width: 92%;
    margin: 0 auto;
    padding: 0 15px;
  }
  &__col {
    padding: 0 15px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &-2 {
      border-right: solid 1px #c7cbdc;
    }
  }
  &__image {

  }
  &__text-1 {
    max-width: 210px;
    text-align: right;
    font-weight: 700;
    color: #626262;
  }
  &__text-2 {
    text-align: left;
    font-weight: 400;
    color: #626262;
  }
  &__btn-close {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 9px;
    border: solid 1px #c2c3c6;

    &:before, &:after {
      position: absolute;
      top: 8px;
      left: 17px;
      content: ' ';
      height: 18px;
      width: 1px;
      background-color: #7e7f82;
    }
    &:hover {
      background-color: #eaebee;
      cursor: pointer;
      border: solid 1px #bcbdc0;
      &:before, &:after {
        background-color: #38393c;
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

  }
  @media screen and (max-width: 900px){
    &__text-2{
      line-height: 1.5em;
    }
  }
  @media screen and (max-width: 900px){
    display: none;
  }
}

header {
  height: 140px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  overflow: hidden;
  .navbar {
    background: #f3f4f7;
    z-index: 1030;
    .container {
      max-width: 1700px;
      width: 92%;
    }
  }
  .navbar-header {
    width: 100%;
    position: relative;
    z-index: 1;
    height: @header-top-height;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -50vw;
      background: url(/frontend/web/images/itarian/top-bg.png) repeat-y top left;
      background-size: contain;
      width: 200vw;
      height: @header-top-height;
      z-index: -1;
    }
    .navbar-toggle {
      position: absolute;
      right: 0;
      margin-top: 18px;
      .icon-bar {
        background-color: #fff;
        margin: 6px 0;
        width: 30px;
        height: 3px;
      }
    }

    .navbar-brand {
      line-height: @header-top-height;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;
    }
    .contact-us {
      float: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: @header-top-height;
      font-size: 14px;
      &__text {
        position: relative;
        font-size: 20px;
        font-weight: 500;
      }
      &__select {
        position: relative;
        display: flex;
        align-items: center;
        min-width: 275px;
        height: 40px;
        margin: 0 0 0 20px;
        padding: 0 15px;
        background-color: #1d3080;
        list-style: none;
        border: solid 1px #2f45aa;
        border-radius: 9px;
        &:hover {
          .contact-us__dropdown {
            display: flex;
          }
        }
        &:before, &:after {
          content: '';
          position: absolute;
          top: 14px;
          right: 15px;
          display: block;
          width: 8px;
          height: 8px;
          border-left: solid 1px #ffffff;
          border-bottom: solid 1px #ffffff;
        }
        &:before {
          transform: rotate(135deg);

        }
        &:after {
          top: 17px;
          transform: rotate(-45deg);
        }
      }
      &__dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        flex-direction: column;
        width: 100%;
        margin: 2px 0 0 0;
        padding: 15px 0 0 0;
        color: #040404;
        list-style: none;
        background-color: #ffffff;
        border: solid 1px #2f45aa;
        border-radius: 9px;
      }
      &__title {
        padding: 5px 15px 5px 15px;
        font-weight: 500;
      }
      &__email {
        a {
          display: block;
          height: 30px;
          line-height: 30px;
          padding: 0 15px 0 30px;
          color: #040404;
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
          &:hover {
            text-decoration: none;
            color: #ffffff;
            background-color: #182970;
          }
        }
        &--line {
          border-top: solid 1px #e7e9f1;
          a {
            height: 36px;
            line-height: 36px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

          }
        }
      }

    }

  }
  .navbar-collapse {
    position: relative;
    z-index: 0;
    color: #ffffff;
    #cssmenu {
      color: @menu-item-color;

      .menu-top-level-item {
        padding: 10px 10px;
        text-transform: uppercase;
        font-weight: 400;
        color: @menu-item-color;
        &:hover {
          color: #192a6f;
          //&:before{
          //  content: "";
          //  position: absolute;
          //  bottom: 0;
          //  left: 0;
          //  height: 2px;
          //  width: 100%;
          //  background: #192a6f;
          //}
        }
        @media screen and (max-width: 1260px) {
          //padding: 10px 7px;
        }

      }

      .nav {
        @media screen and (max-width: 1200px) {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding-left: auto;
          font-size: 15px;
        }
        &.navbar-nav {
          background: @menu-bg-color;
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -15px;
            width: 100vw;
            height: 100%;
            background: @menu-bg-color;
          }
        }
        &.navbar-right {
          font-size: 14px;
          @media screen and (max-width: 1200px) {
            justify-content: flex-start;
            width: 100%;
          }
        }
        ul {
          list-style: none;
          padding: 0;
        }
        .inner-menu {
          //background: #fff;
          border-top: 2px solid #e3e3e3;
          //border-bottom: 1px solid #e3e3e3;
          left: 0;
          right: 0;
          position: absolute;
          display: none;
          z-index: 1000;
          .has-sub {
            float: left;
          }
          .menu-title {
            padding: 0 10px 0 0;
            font-weight: 400;
          }
          li {
            a {
              color: @menu-item-color;
            }
          }
        }

        > li {
          &:hover {
            > a {
              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background: #192a6f;
              }
            }
            .inner-menu {
              min-width: 100vw;
              display: block;

              > ul {
                display: inline-block;
                width: auto;
                background: @menu-bg-color;
                padding: 12px 15px 10px 15px
              }

            }
          }
        }

        .menu-item-level-1,
        .menu-item-level-2 {
          padding: 7px 15px 0 0;
          li {
            margin: 10px 0;
          }
        }
      }
    }

  }

}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {
  .navbar-fixed-top .navbar-collapse {
    max-height: initial;
  }

  header .navbar-collapse #cssmenu .nav {
    display: flex;
    flex-direction: column;
    margin: 7.5px 0;
  }

  header .navbar-collapse #cssmenu .nav ul,
  header .navbar-collapse #cssmenu .nav ul li {
    display: block;
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav .menu-item-level-2 {
    padding-left: 20px;
  }

  header .navbar-collapse #cssmenu .nav .inner-menu {
    position: relative;
  }

  header .navbar-collapse #cssmenu .nav.navbar-right {
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav.navbar-nav:before {
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav > li:hover > a:before {
    background-color: transparent;
  }

  header .navbar-collapse #cssmenu .nav > li:hover .inner-menu {
    min-width: auto;
  }

  header .navbar-header .contact-us {
    display: none;
  }
}
